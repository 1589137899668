<template>
  <div class="firstBox">
    <div class="itemBox">
      <div class="title">抖音小程序名称：</div>
      <div class="content" style="color: #999">
        <!-- <div v-if="authorizationSteps >= 1">{{ name }}</div> -->
        <!-- v-else -->
        <div class="inBox">
          <el-input :spellcheck="false" ref="inputMy" v-model="name"></el-input>
          <!-- v-if="authorizationSteps == 0" -->
          <img src="@/assets/image/isUpdate.png" alt="" />
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">appID：</div>
      <div class="content" style="color: #999">
        <!-- <div v-if="authorizationSteps >= 1">{{ dataInfo.auth_app_id }}</div> -->
        <!-- v-else -->
        <div class="inBox">
          <!--  :disabled="authorizationSteps >= 1"-->
          <el-input :spellcheck="false" ref="inputMy" v-model="dataInfo.auth_app_id"></el-input>
          <!-- v-if="authorizationSteps == 0" -->
          <img src="@/assets/image/isUpdate.png" alt="" />
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="title">秘钥：</div>
      <div class="content" style="color: #999">
        <!-- <div v-if="authorizationSteps >= 1">{{ dataInfo.secret }}</div> -->
        <!-- v-else -->
        <div class="inBox">
          <!--  :disabled="authorizationSteps >= 1" -->
          <el-input :spellcheck="false" ref="inputMy" v-model="dataInfo.secret"></el-input>
          <!-- v-if="authorizationSteps == 0" -->
          <img src="@/assets/image/isUpdate.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          name: '',
          auth_app_id: '',
          secret: ''
        }
      }
    },
    authorizationSteps: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      name: ''
    }
  },
  methods: {
    // 保存信息
    preservation() {
      let that = this
      this.dataInfo.name = this.name
      if (!this.dataInfo.name || !this.dataInfo.auth_app_id || !this.dataInfo.secret) {
        this.$message({
          message: '请将信息配置完整',
          type: 'warning'
        })
        return
      }
      this.$axios
        .post(this.$api.set.DouyinInfo, {
          name: this.dataInfo.name,
          auth_app_id: this.dataInfo.auth_app_id,
          secret: this.dataInfo.secret
        })
        .then((res) => {
          if (res.code == 0) {
            this.$emit('updateBasicsSu', this.name)
            that.$message({
              message: '保存成功',
              type: 'success'
            })
          } else {
            that.$message({
              message: '修改成功',
              type: 'error'
            })
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  align-items: center;
  .title {
    width: 150px;
    text-align: right;
    white-space: nowrap;
    flex-shrink: 0;
    color: #818181;
  }
  .content {
    width: calc(100% - 160px);
    flex-shrink: 0;
    padding: 25px 0 23px 0;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    .inBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-input {
      width: 90%;
      line-height: normal !important;
      /deep/ input {
        line-height: normal !important;
        letter-spacing: 1px;
        border: 1px solid transparent;
        padding: 0;
        height: inherit;
      }
    }

    .btn {
      color: #00ca5a;
      cursor: pointer;
      user-select: none;
      margin-left: 10px;
    }
  }
}
</style>
