<template>
  <div class="firstBox">
    <div class="itemBox">
      <div class="title">appID：</div>
      <div class="content" style="color: #999">
        {{ dataInfo.auth_app_id }}
      </div>
    </div>
    <div class="itemBox">
      <div class="title">商户号ID：</div>
      <div class="content" style="color: #999">
        <el-input :spellcheck="false" ref="inputMy" v-model="info.m_id"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itemBox">
      <div class="title">SALT：</div>
      <div class="content" style="color: #999">
        <el-input :spellcheck="false" ref="inputMy" v-model="info.api_key"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itemBox">
      <div class="title">AppSecret：</div>
      <div class="content" style="color: #999">
        {{ dataInfo.secret }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          auth_app_id: '',
          secret: ''
        }
      }
    },
    authorizationSteps: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      info: {},
      // 默认第一次为add
      isHave: false
    }
  },

  methods: {
    // 保存信息
    preservation() {
      let that = this
      if (!this.info.m_id || !this.info.api_key) {
        this.$message({
          message: '请将信息配置完整',
          type: 'warning'
        })
        return
      }
      let obj = {
        type: 8,
        sets: {
          app_id: this.dataInfo.auth_app_id,
          api_key: this.info.api_key,
          m_id: this.info.m_id,
          secret: this.dataInfo.secret
        }
      }
      this.$axios
        .post(this.isHave ? this.$api.set.Settingedit : this.$api.set.Settingadd, obj)
        .then((res) => {
          if (res.code == 0) {
            this.$emit('updatePaySu')
            that.$message({
              message: '保存成功',
              type: 'success'
            })
          } else {
            that.$message({
              message: '修改成功',
              type: 'error'
            })
          }
        })
    },
    getInfo() {
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 8
        })
        .then((res) => {
          console.log(res) // 支付秘钥 api_key
          if (res.code === 0) {
            if (res.result.info == '') {
              this.info = {
                m_id: '',
                api_key: ''
              }
            } else {
              this.isHave = true
              this.info = res.result.info.sets
            }
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  align-items: center;
  .title {
    width: 150px;
    text-align: right;
    white-space: nowrap;
    flex-shrink: 0;
    color: #818181;
  }
  .content {
    width: calc(100% - 160px);
    flex-shrink: 0;
    padding: 25px 0 23px 0;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    .inBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-input {
      width: 90%;
      line-height: normal !important;
      /deep/ input {
        line-height: normal !important;
        letter-spacing: 1px;
        border: 1px solid transparent;
        padding: 0;
        height: inherit;
      }
    }

    .btn {
      color: #00ca5a;
      cursor: pointer;
      user-select: none;
      margin-left: 10px;
    }
  }
}
</style>
